var site = site || {};
var generic = generic || {};

(function ($) {
  $(document).on('email_signup:js-pony_popup', function () {
    $('.js-pony_subscribe').click(function (event) {
      var ponySku = Drupal.settings.globals_variables.pony_offer_sku;
      var currentSku = parseInt($('.product__inventory-status.js-inv-status-list').data('sku-base-id'));

      if (jQuery.inArray(currentSku, ponySku) !== -1) {
        event.preventDefault();
        Drupal.behaviors.ponypopupOfferV1.launchPopupWindow();
        Drupal.behaviors.ponypopupOfferV1.bindEvents();
      }
    });
  });
  Drupal.behaviors.ponypopupOfferV1 = {
    // Launch popup even if cookie is set
    debug: false,

    // Inline template name that is grabbed and shown in pop-up.
    templateName: 'pony_popup_offer_v1',

    // Class name to be used for colorbox popup launch.
    colorBoxClassName: 'js-popup-offer-overlay-cbox pony_popup_offer_bg',

    // The four larger elements that are displayed at different
    // stages of the popup process.
    popupElements: {
      first: '.js-popup-offer-outer-wrap',
      thankYou: '.js-popup-offer-thank-you',
      signedUp: '.js-popup-offer-signed-up'
    },
    // Store which div class was showing when the terms were opened.
    elementShowing: '',

    // Store the original colorbox close method.
    originalColorboxCloseMethod: $.colorbox.close,

    // Given the way the terms & conditions are shown in the colorbox overlay,
    // the close function must be overwritten a bit:
    // If the flag for termsShowing is true, just hide the terms, don't close the box.
    // Otherwise, act normally, by calling the original close method.
    overwriteColorboxClose: function () {
      var self = this;

      $.colorbox.close = function () {
        self.originalColorboxCloseMethod();
      };
    },

    // Make the RPC call for the email sign-up form in the pop-up.
    processForm: function () {
      var self = this;
      // Get all the fields in code because 'serialize' encodes bad emails and doesn't decode plus symbols.
      var formFields = [
        '_SUBMIT',
        'COUNTRY_ID',
        'LANGUAGE_ID',
        'PC_EMAIL_PROMOTIONS',
        'LAST_SOURCE',
        'ORIGINAL_SOURCE',
        'PC_EMAIL_ADDRESS',
        'FIRST_NAME',
        'LAST_NAME',
        'REQUIRE_FIRST_NAME',
        'REQUIRE_LAST_NAME'
      ];
      // Create an object to hold all the parameters and their values.
      var paramObj = {};

      // Loop through each field and get its value.
      $.each(formFields, function (index, value) {
        var formField = $('.js-popup-offer-sign-up input[name=' + value + ']');

        paramObj[value] = formField.val();
      });
      // Send the data via a JSON RPC call.
      generic.jsonrpc.fetch({
        method: 'rpc.form',
        params: [paramObj],
        onSuccess: function () {
          $(self.popupElements.thankYou).show();
          self.elementShowing = self.popupElements.thankYou;
          $('.js-popup-offer-overlay-cbox.pony_popup_offer_bg').addClass('bg_thankyou');

          // Regardless, hide the original sign up form portion.
          $(self.popupElements.first).hide();
        },
        onFailure: function (jsonRpcResponse) {
          // Store messages and display them in error div element.
          var messages = jsonRpcResponse.getMessages();

          $('.js-popup-offer-error-messages').empty().html(messages[0].text).removeClass('hidden');
        }
      });
    },

    // Launch the popup window with the appropriate element visible.
    launchPopupWindow: function (elementToShow) {
      var self = this;
      var okayToLaunchPopup = true;

      $('.js-popup-offer-overlay-cbox.pony_popup_offer_bg').removeClass('bg_thankyou');
      // Get the popup template.
      var templateHTML = site.template.get({
        name: self.templateName
      });
      // Get value of data attribute 'enable-popup', it's should be either 'yes' or 'no'.
      var $templateContext = $(templateHTML);
      var enablePopup = $templateContext.data('enable-popup');

      // If not set, give it the value of 'yes'
      if (typeof enablePopup === 'undefined') {
        enablePopup = 'yes';
      }

      // Set a default elementToShow just in case.
      if (elementToShow === undefined) {
        elementToShow = self.popupElements.first;
        // If there was no elementToShow parameter, then this was triggered by visiting some page, not triggered by an email signup.
        // Therefore, the value of enablePopup must be used to see if the popup should actually launch.
        if (enablePopup === 'no' || enablePopup === 'false' || !enablePopup) {
          okayToLaunchPopup = false;
        }
      }
      if (self.debug) {
        okayToLaunchPopup = true;
      }
      if (okayToLaunchPopup) {
        // Magic number calculations to show popup below gnav
        var getMaxHeight = function () {
          var maxHeight = $(window).outerHeight(false) - $('.js-site-header-sticky').outerHeight(false) * 2;

          if (maxHeight < 315) {
            maxHeight = 315;
            // Process of trial and error to make the popup look good at different widths without redoing the popup css
          } else if (maxHeight > 340) {
            var windowWidth = $(window).outerWidth(false);

            if (windowWidth >= 768) {
              // iPad portrait and up
              maxHeight = 390;
              // Mobile up
            } else if (windowWidth >= 480) {
              maxHeight = 360;
              // Mobile
            } else {
              maxHeight = 340;
            }
          }

          return maxHeight;
        };
        // END Magic number calculations to show popup below gnav
        var maxHeight = getMaxHeight();

        // Launch the popup with colorbox.
        generic.overlay.launch({
          content: templateHTML,
          width: 554,
          height: 'auto',
          maxHeight: maxHeight,
          cssClass: self.colorBoxClassName
        });

        var lastWindowWidth = $(window).width();

        $(window)
          .off('resize.popupOffer')
          .on('resize.popupOffer', function () {
            // Scrolling in Safari triggers resize, avoid that
            if ($(window).width() !== lastWindowWidth) {
              $.colorbox.resize({ height: getMaxHeight() });
              lastWindowWidth = $(window).width();
            }
          });

        // Loop through all popupElements and only show the one specified
        // in the parameter elementToShow (or the default value).
        for (var elemKey in self.popupElements) {
          if (self.popupElements.hasOwnProperty(elemKey)) {
            var elemValue = self.popupElements[elemKey];

            if (elemValue === elementToShow) {
              $(elemValue).show();
              self.elementShowing = elemValue;
            } else {
              $(elemValue).hide();
            }
          }
        }
      }
    },

    bindEvents: function () {
      var self = this;

      $('.js-popup-offer-submit').click(function (e) {
        e.preventDefault();
        self.processForm();
      });
    }
  };
})(jQuery);
